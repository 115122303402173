import * as bootstrap from 'bootstrap';
import '@popperjs/core'
import IMask from 'imask'
import htmx from 'htmx.org'

window.bootstrap = bootstrap;
window.htmx = htmx;

const doc = document.getElementById('id_doc')
if (doc) {
    IMask(doc, [
        { mask: '000.000.000-00' },
        { mask: '00.000.000/0000-00' }
    ])
}
